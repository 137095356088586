

function Dashboard() {

  return (
    <>
      <h1>You've made it to the dashboard</h1>
    </>
  )
}

export default Dashboard
