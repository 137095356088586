
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Dashboard from './pages/Dashboard'
import Home from './pages/Home'
import './styles/global.scss'

class App extends React.Component {

  render() {
    return (
      <Router>
        <Routes>
          {/* authenticated pages */}
          <Route path="/dashboard" element={<Dashboard />} />

          {/* unauthenticated pages */}
          <Route path="/" element={<Home />} />

        </Routes>
      </Router >
    )
  }
}

export default App
