import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { doc, getDoc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyA9L4JEDXKjvvGs_Tgquz2M-hLA07WQoO8",
  authDomain: "solana-system.firebaseapp.com",
  projectId: "solana-system",
  storageBucket: "solana-system.appspot.com",
  messagingSenderId: "456387781548",
  appId: "1:456387781548:web:cfba6f5db62b529bec9009",
  measurementId: "G-0X09KFSJJT"
};

initializeApp(firebaseConfig)
const functions = getFunctions()
const auth = getAuth()
const db = getFirestore()

export const signInWithWallet = (publicKey: string) => {
  const logInUser = httpsCallable(functions, 'logInUser')

  return logInUser({ token: publicKey }).then((response) => {
    return signInWithCustomToken(auth, response.data as string).then((userCredential) => {
      return userCredential.user
    })
  }).catch((error) => {
    const errorCode = error.code
    const errorMessage = error.message
    console.log('error', errorCode, errorMessage)
  })
}

export const updateUser = async (walletPublicKey: string, userId?: string) => {
  if (!userId) { return }

  const docRef = doc(db, "users", userId)
  const docSnap = await getDoc(docRef)
  const timestamp = serverTimestamp()

  let data: any = { walletPublicKey, updatedAt: timestamp }
  if (!docSnap.exists()) {
    data.createdAt = timestamp
  }

  setDoc(docRef, data, { merge: true })

}
