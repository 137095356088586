import { PublicKey } from '@solana/web3.js'
import { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Nav, Row } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"
import { PhantomProvider } from '../interfaces/PhantomProvider'
import '../styles/stars.scss'
import { signInWithWallet, updateUser } from '../utils/firebaseUtils'

function Home() {

  const [provider, setProvider] = useState<PhantomProvider>()
  const [providerPubKey, setProviderPub] = useState<PublicKey>()

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const navigate = useNavigate()

  const loginHandler = () => {
    if ("solana" in window) {
      // @ts-ignore
      const localProvider = window.solana as PhantomProvider

      setProvider(localProvider)

      if (!localProvider.isConnected) {
        localProvider.connect()
      }
    } else {
      handleShow()
    }
  }

  useEffect(() => {
    if (provider) {

      provider.on("connect", async () => {
        console.log("wallet got connected", provider.publicKey)
        setProviderPub(provider.publicKey!)
        const user = await signInWithWallet(provider.publicKey!.toBase58())
        await updateUser(provider.publicKey!.toBase58(), user?.uid)
        if (user) {
          navigate('/dashboard')
        }

      })

      provider.on("disconnect", () => {
        console.log("Disconnected from wallet")
      })

    }
  }, [provider, navigate])

  return (
    <>
      <div className="App">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <Nav className="justify-content-end" activeKey="/home">
          <Nav.Item>
            <Nav.Link>
              {!providerPubKey && <Button style={{ display: 'none' }} className="loginButton" onClick={() => loginHandler()}> Login</Button>}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <header className="App-header">
                <h5 className='mt-5'>Welcome to the</h5>
                <h1 className='display-1 mt-2 mb-5'>Solana System</h1>
                <p className='text-center'>Explore our digital solar system and search for alien life through this NFT project. Development is currently in progress, so join the Discord now for updates and exclusive benefits.</p>
                <a className='fs-5 mt-2' href='https://discord.gg/jwznx28Cth'>Join the Discord</a>
              </header>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Login uses Phantom Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>You must install the <a rel="noreferrer" target="_blank" href="https://phantom.app/help/installing-phantom">Phantom Wallet</a> before contining.</p>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default Home
